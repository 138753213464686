import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";



const useStyles = makeStyles((theme) => ({
    wrapper: {
       width: "90vw",
       maxWidth: "1200px",
       margin: "150px auto 150px",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        margin: "0px auto 50px",
      },

    },
    image: {
      width: "100%",
    },
    item: {
      padding: "40px 80px",
      alignSelf: "center",
      [theme.breakpoints.down("sm")]: {
        padding: "0px 50px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "0px 20px",

        "& ul": {
          paddingLeft: "25px"
        }

      },
    },
    leftItem: {
      [theme.breakpoints.up("md")]: {
        borderRight: "1px solid #666666"
      },
    }
}));

export default function InfoText(props) {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.wrapper}>
        <Grid item xs={12} md={6} className={`${classes.item} ${classes.leftItem}`}>
           <h2 >{props.heading}</h2>
        </Grid>

        <Grid item xs={12} md={6} className={classes.item} >
        <ul>
        {props.list.map((item) => 
          <li><p>{item}</p></li>
        )}
        {props.heading === "Vertrauen Sie dem Experten für Sanitär" && 
        <li> <Link style={{ color: "#E90014" }} to="/bad-konzepte" className={classes.link}><p>Traumbäder</p></Link></li>}
        </ul>
        </Grid>
      </Grid>
    </>
  );
}

import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Button from "./layout/button"


const useStyles = makeStyles((theme) => ({
    wrapper: {
       width: "90vw",
       margin: "0px auto 150px",
      display: "flex",

    },
    image: {
      width: "100%",
    },
    item: {
      padding: "40px 80px",
      alignSelf: "center",
      [theme.breakpoints.down("md")]: {
        padding: "10px",
      },
    },
    leftItem: {
      [theme.breakpoints.up("md")]: {
        borderRight: "1px solid #666666"
      },
    }
}));

export default function BildTextZwei(props) {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.wrapper}>
        <Grid item xs={12} md={6} className={`${classes.item} ${classes.leftItem}`}>
          <img src={props.url} alt={props.alt} className={classes.image} />
        </Grid>

        <Grid item xs={12} md={6} className={classes.item} >
          <h2 >{props.heading}</h2>
          {props.text.split("\n").map((text) => (
            <p>{text}</p>
          ))}
          <Button text={props.button} link={props.slug}></Button>
        </Grid>
      </Grid>
    </>
  );
}

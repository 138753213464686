import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";



const useStyles = makeStyles((theme) => ({
    wrapper: {
       width: "50%",
       height: "320px",
       margin: "0px auto 5px",
       display: "inline-flex",
       [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        height: "660px",
        maxWidth: "320px",
        margin: "0px auto",
        display: "flex",
      },

    },
    image: {
      backgroundPosition: "center top",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      [theme.breakpoints.down("xs")]: {
        height: "380px",
      },
    },
    text: {
      padding: "20px",
      height: "320px",
      alignSelf: "center",
      [theme.breakpoints.down("xs")]: {
        padding: "10px",
      },
    },
}));

export default function MitarbeiterLeft(props) {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.wrapper}>
        <Grid item xs={12} sm={4} md={4} className={`${classes.image}`} style={{backgroundImage: props.url}}>
       </Grid>

        <Grid item xs={12} sm={8} md={8} className={classes.text} style={{backgroundColor: props.color}}>
        <p className="font__red"><i>{props.heading}</i></p>
          <h4><b>{props.name}</b> </h4>
          <p>{props.titel}</p>
          <p>{props.text}</p>
        </Grid>
      </Grid>
    </>
  );
}

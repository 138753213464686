import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import MitarbeiterRight from "./mitarbeiterRight";
import MitarbeiterLeft from "./mitarbeiterLeft";

import data from "../data/team.json";
import KurtHengel from "../images/KurtHengel.jpg";
import SimonSchädlich from "../images/SimonSchädlich.jpg";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "90vw",
    height: "645px",
    margin: "0px auto 100px",
    display: "flex",
    backgroundColor: "#F7F7F7",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "50px",
      backgroundColor: "#fff",
      height: "unset"

    },
  },
  mitarbeiter: {
    "& div": {
      width: "100%",
    },
  },
  item: {
    padding: "40px 80px",
    alignSelf: "center",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      padding: "40px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "50vh",
      display: "flex",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "20px",
    },
  },
  leftItem: {
    [theme.breakpoints.up("md")]: {
      borderRight: "1px solid #666666",
    },
  },
}));

export default function TeamHero(props) {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.wrapper}>
        <Grid item xs={12} md={6} className={classes.item}>
          <div>
            <h1 className="uppercase">{data.heading}</h1>
            {data.intro.split("\n").map((text) => (
            <p>{text}</p>
          ))}
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.mitarbeiter}>
          <MitarbeiterLeft
            url={`url("${KurtHengel}")`}
            alt={data.mitarbeiter[0].name}
            heading={data.mitarbeiter[0].heading}
            name={data.mitarbeiter[0].name}
            titel={data.mitarbeiter[0].titel}
            text={data.mitarbeiter[0].text}
            color={data.mitarbeiter[0].color}
          />
          <MitarbeiterRight
            url={`url("${SimonSchädlich}")`}
            alt={data.mitarbeiter[1].name}
            heading={data.mitarbeiter[1].heading}
            name={data.mitarbeiter[1].name}
            titel={data.mitarbeiter[1].titel}
            text={data.mitarbeiter[1].text}
            color={data.mitarbeiter[1].color}
          />
        </Grid>
      </Grid>
    </>
  );
}

import React, { Component } from "react";
import "../App.css";
import styled from "styled-components";
import { gsap, Power1 } from "gsap";

import Layout from "../components/layout/layout";
import TextZwei from "../components/textZwei";
import InfoText from "../components/infoText";
import MitarbeiterStimme from "../components/mitarbeiterstimme";

//Data

import data from "../data/team.json";


const TextContainer = styled.div`
  padding: 0px 0px 50px 0px;
  text-align: center;

  @media (max-width: 767px) {
    padding: 0px 20px 50px 20px;

  }
`;

class Karriere extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    let container = document.querySelector(".content_container");

    let tl = gsap.timeline();
    tl.to(container, { duration: 0, opacity: 0, y: 30 }).to(container, {
      duration: 0.8,
      opacity: 1,
      y: 0,
      ease: Power1.easeOut,
    });
  }



  render() {
    return (
      <Layout pageTitle="Karriere bei der Firma Hengel" description="Finden Sie unsere aktuellen offenen Stellen.">
        <div>       
          <div
            className="team--background"
            alt="Firma Hengel"
            id="karriere"
          ></div>
          <div id="karriere"></div>
          <InfoText
            heading="Karrieremöglichkeiten bei Kurt Hengel"
            list={data.details.list}
          />
          <TextContainer>
            <h3>Aktuelle offene Stellen</h3>
            <br/>
            <h5>
              Wir sind auf der Suche nach einem <b>Techniker/Ingenieur</b> der
              Versorgungstechnik, der den Wandel mitgestalten möchte.
            </h5>
            <br/>

            <h4> Ihre Bewerbung senden Sie an</h4>
            <p>
              Fa. Kurt Hengel
              <br />
              Bahnhofstr. 14
              <br />
              71706 Markgröningen
              <br />
              Oder per Mail an: Britta.Kammerer@hengel-heizung.de
            </p>
          </TextContainer>

          <TextZwei heading={data.info.heading} text={data.info.text} />
          <MitarbeiterStimme
            heading={data.mitarbeiterstimme.heading}
            text={data.mitarbeiterstimme.text}
          />
        </div>
      </Layout>
    );
  }
}

export default Karriere;


import React from "react"
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";



const useStyles = makeStyles((theme) => ({
  buttonWhite: {
    backgroundColor: "transparent",
    border: "0px",
    marginTop: "30px",
    "& p": {
      margin: "0px",
      textAlign: "center",
      letterSpacing: "2px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textTransform: "uppercase",
      [theme.breakpoints.down("md")]: {
        letterSpacing: "1px",

      },
    },

    "& a:hover hr": {
      transition: "all 0.3s ease-out",
      width: "60px",
      [theme.breakpoints.down("md")]: {
        width: "40px",

      },
     }
  },
  link: {   
     display: "inline-flex",
     "& hr": {
       width: "50px",
       marginRight: "20px",
       border: "0px",
       borderTop: "1px solid rgb(233, 0, 20)",
       transition: "all 0.3s ease-out",
       [theme.breakpoints.down("md")]: {
        width: "30px",
        marginRight: "10px",

      },
     },



  }
}))

export default function Button(props) {
  const classes = useStyles();

  return ( 
    <>
      <button className={classes.buttonWhite}>
        <Link to={props.link} className={classes.link}><hr/><p>{props.text}</p></Link>
      </button>

  </>
  )
}



import React from "react";
import Navbar from "./navbar";
import { Helmet } from "react-helmet";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Zoom from "@material-ui/core/Zoom";
import { makeStyles } from "@material-ui/core/styles";
import MobileMenu from "./mobileMenu";

import Footer from "./footer";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  mainGrid: {
    width: "90vw",
    margin: "0px auto",
  },
  mobileButton: {
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  desktopButton: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

function ScrollTopMobile(props) {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor-mobile"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

export default function Layout(props) {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{props.pageTitle}</title>
        <meta name="description" content={props.description}></meta>
        <meta name="robots" content={props.index}></meta>
      </Helmet>
      <Navbar className={classes.nav} />
      <MobileMenu className={classes.mobileMenu} />
      <div className={`${classes.container} content_container`}>
        {props.children}
      </div>
      <div className={classes.desktopButton}>
        <ScrollTop {...props}>
          <Fab
            style={{ backgroundColor: "#f7f7f7", color: "#181818" }}
            size="small"
            aria-label="scroll back to top"
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </div>

      <div className={classes.mobileButton}>
        <ScrollTopMobile {...props}>
          <Fab
            style={{ backgroundColor: "#f7f7f7", color: "#181818" }}
            size="small"
            aria-label="scroll back to top"
          >
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTopMobile>
      </div>
      <Footer />
    </>
  );
}

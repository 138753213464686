
import React from "react"
import { makeStyles } from "@material-ui/core/styles";



const useStyles = makeStyles((theme) => ({
  buttonWhite: {
    width: "180px",
    height: "50px",
    backgroundColor: "#fff",
    border: "1px solid #666666",
    "& p": {
      margin: "0px",
      textAlign: "center",
      letterSpacing: "2px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "400",
    },
    [theme.breakpoints.down("md")]: {
      width: "160px",
      height: "40px",
    },
  }
}))

export default function ButtonWhiteFooter(props) {
  const classes = useStyles();

  return ( 
    <>
      <button className={classes.buttonWhite}>
        <a href={props.link}><p className="uppercase">{props.children}</p></a>
      </button>

  </>
  )
}



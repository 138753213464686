import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "./layout/button"

const useStyles = makeStyles((theme) => ({
    wrapper: {
      margin: "0px auto 150px auto",
      display: "flex",
      backgroundColor: "#F3EEEB",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "50px",

      },

    },
    innerWrapper: {
      width: "90vw",
      margin: "0px auto",
      [theme.breakpoints.down("sm")]: {
        padding: "50px 10px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "30px 10px",
      },
    },
    image: {
      width: "100%",
    },
    item: {
      padding: "80px 40px 80px 80px",
      alignSelf: "center",
      [theme.breakpoints.down("sm")]: {
        "& h2": {
          marginBottom: "0px",
        },
        "& p": {
          marginTop: "0px",
        },
        padding: "10px",
      },
    }
}));

export default function TextZwei(props) {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.wrapper}>
      <Grid container className={classes.innerWrapper}>
        <Grid item xs={12} md={4} className={classes.item}  >
          <h2>{props.heading}</h2>
        </Grid>
        <Grid item xs={12} md={5} className={classes.item}>
          {props.text.split("\n").map((text) => (
            <p>{text}</p>
          ))}
          {props.button && <Button text={props.button} link={props.slug}></Button> }
        </Grid>
        </Grid>
      </Grid>
    </>
  );
}

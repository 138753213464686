import React, { Component } from "react";
import "../App.css";
import styled from "styled-components";
import Layout from "../components/layout/layout";
import ButtonWhiteFooter from "../components/layout/buttonWhiteFooter";

const TextWrapper = styled.div`
  max-width: 90vw;
  margin: 50px auto;
  height: 60vh;
  padding: 100px;
  background-color: #f7f7f7;
  display: block;
  text-align: center;
  @media only screen and (max-width: 767px) {
    padding: 30px;
  }
`;

class NotFound extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Layout
        pageTitle="404 - Kann nicht gefunden werden"
        description="404 - Kann nicht gefunden werden"
        index="nofollow, noindex"
      >
        <TextWrapper className="hero--background">
          <h1 className="uppercase">Ups... </h1>
          <br />
          <h2>Die von Ihnen gewünschte Seite ist leider nicht verfügbar.</h2>
          <h4>Versuchen Sie es doch einfach hier: </h4>
          <ButtonWhiteFooter link="/" >
            Zur Startseite
          </ButtonWhiteFooter>
        </TextWrapper>
      </Layout>
    );
  }
}

export default NotFound;

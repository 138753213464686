import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

// Pages
import Home from "./pages/Home";
import SanitaerLeistung from "./pages/Sanitär";
import HeizungsbauLeistungen from "./pages/Heizungsbau";
import Badkonzepte from "./pages/Badkonzepte";
import EnergieLeistungen from "./pages/Energie";
import Team from "./pages/Team";
import Karriere from "./pages/Karriere";
import Unternehmen from "./pages/Unternehmen";
import Kontakt from "./pages/Kontakt";
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";
import NotFound from "./pages/404"

import "./App.css";



function App() {

  return (
    <div className="App">
      <Router>
        <div className="container"> 
          <Switch >
            <Route exact path="/" component={Home} />
            <Route exact path="/sanitär" component={SanitaerLeistung} />
            <Route exact path="/heizungsbau" component={HeizungsbauLeistungen} />
            <Route exact path="/bad-konzepte" component={Badkonzepte} />
            <Route exact path="/erneuerbare-energien" component={EnergieLeistungen} />
            <Route exact path="/team" component={Team} />
            <Route exact path="/karriere" component={Karriere} />
            <Route exact path="/unternehmen" component={Unternehmen} />
            <Route exact path="/kontakt" component={Kontakt} />
            <Route exact path="/impressum" component={Impressum} />
            <Route exact path="/datenschutz" component={Datenschutz} />
            <Route path="/404" component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        

        </div>
      </Router>
    </div>
  );
}

export default App;

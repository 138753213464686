import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

// Data
import menudata from "../data/menu.json";

const useStyles = makeStyles((theme) => ({
  Wrapper: {
    height: "80px",
    width: "90vw",
    margin: "0px auto 150px",
    backgroundColor: "#F3EEEB",
    justifyContent: "space-evenly",
    alignContent: "center",

    "& a": {
      margin: "5px 0px",
    },
    "& p": {
      margin: "5px 0px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "120px",

    },
  },
}));

export default function HeroNavbar(props) {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.Wrapper}>
        {menudata.Submenu.map((item) => (
          <Link className="uppercase" to={item.slug}>
            {item.name}
          </Link>
        ))}
      </Grid>
    </>
  );
}

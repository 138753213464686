import React, { Component } from "react";
import "../App.css";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Layout from "../components/layout/layout";
import { gsap, Power1 } from "gsap";

const ContainerWrapper = styled.div`
  min-height: 60vh;
  width: 90vw;
  margin: 0px auto 100px auto;
`;
const TextWrapper = styled.div`
  background-color: #f3eeeb;
  padding: 50px 80px;
  @media only screen and (max-width: 1024px) {
    padding: 50px;
  }
  @media only screen and (max-width: 767px) {
    padding: 30px;
  }
`;

class Kontakt extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    let container = document.querySelector(".content_container");

    let tl = gsap.timeline();
    tl.to(container, { duration: 0, opacity: 0, y: 30 }).to(container, {
      duration: 0.8,
      opacity: 1,
      y: 0,
      ease: Power1.easeOut,
    });
  }
  render() {
    return (
      <Layout
        pageTitle="Kontakt"
        description="Firma Hengel | Markgröningen | Wir freuen uns auf Ihre Nachricht oder Ihren Anruf."
      >
        <ContainerWrapper>
          <Grid container>
            <Grid item xs={12} md={6} className="kontakt--background"></Grid>
            <Grid item xs={12} md={6}>
              <TextWrapper>
                <h1 className="uppercase">Kontakt</h1>
               {/*  <h5 style={{ color: "#E90014" }}>Vom 19.08.2024 bis zum 01.09.2024 haben wir Betriebsferien. <br/> Ab dem 02.09.2024 sind wir gerne wieder für Sie da.</h5>
                
                <p>
                  In dringenden Notfällen wenden sie sich an die
                  Notfallnummer unter{" "}
                  <a href="tel:01739625813" style={{ color: "#E90014" }}>
                    01739625813
                  </a>
                  .
                </p> */}
                <h4>
                  <b>So erreichen Sie uns</b>
                </h4>
                <p>
                  <a href="tel: +4971455246"> Telefon: +49 (7145) 5246</a>
                  <br />
                </p>
                <p>
                  <a href="mailto: info@hengel-heizung.de">
                    E-Mail: info@hengel-heizung.de{" "}
                  </a>
                </p>
                <h4>
                  <b>Anschrift</b>
                </h4>
                <p>
                  Firma Hengel
                  <br />
                  Bahnhofstraße 14 <br />
                  71706 Markgröningen
                </p>
                <p>
                  <b> Eingang im Hof </b>
                </p>
                <h4>
                  <b>Bürozeiten</b>
                </h4>
                <p>
                  Montag bis Donnerstag
                  <br />
                  08:00 Uhr bis 12:00 Uhr
                  <br />
                  13:30 Uhr bis 16:00 Uhr
                  <br />
                </p>
                <p>
                  Freitag
                  <br />
                  08:00 Uhr bis 12:00 Uhr
                </p>
                <p>
                  <b>Termine nach Vereinbarung</b>
                </p>
              </TextWrapper>
            </Grid>
          </Grid>
        </ContainerWrapper>
      </Layout>
    );
  }
}

export default Kontakt;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import { Link } from "react-router-dom";

//Icons
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";

// Layout Components
import ButtonWhiteFooter from "./buttonWhiteFooter";

const HrWrapper = styled.div`
  display: inline-flex;
  width: 100%;
  margin-top: 40px;
  hr {
    width: 50%;
    height: 1px;
    color: #fff;
  }
  span {
    padding: 0px 10px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 45px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  gridContainer: {
    backgroundColor: "#F7F7F7",
  },
  gridHalf: {
    borderRight: "1px solid #979797",
    [theme.breakpoints.down("sm")]: {
      borderTop: "1px solid #979797",
    },
    padding: "30px",
  },
  gridFull: {
    [theme.breakpoints.down("sm")]: {
      borderTop: "1px solid #979797",
    },
    padding: "30px",
    "& svg": {
      [theme.breakpoints.down("sm")]: {
        height: "20px",
      },
    },
  },
  flex: {
    display: "flex",
    width: "fit-content",
    color: "#666",
  },
  greyleft: {
    backgroundColor: "#666666",
    borderTop: "1px solid #979797",
    textAlign: "left",
    paddingLeft: "60px",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      paddingLeft: "0px",
    },
  },
  greyright: {
    backgroundColor: "#666666",
    borderTop: "1px solid #979797",
    textAlign: "right",
    paddingRight: "60px",
    [theme.breakpoints.down("xs")]: {
      borderTop: "0px ",
      textAlign: "center",
      paddingRight: "0px",
    },
  },
  emailIcon: {
    paddingRight: "10px",
  },
}));

function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid className={classes.gridContainer} container spacing={0}>
        <Grid className={classes.gridHalf} item xs={12} sm={6} md={3}>
          <p className="uppercase  ">
            <b>Firma Kurt Hengel</b>
          </p>
          <p className=" ">
            Bahnhofstraße 14 <br />
            71706 Markgröningen
          </p>
          <p>
            <a href="tel:071455246">Tel: 07145/5246 </a>
          </p>
          <p>
            <a href="mailto:info@hengel-heizung.de">
              Mail: info@hengel-heizung.de
            </a>
          </p>
        </Grid>
        <Grid className={classes.gridHalf} item xs={12} sm={6} md={3}>
          <p className="uppercase  ">
            <b>Rufen Sie uns an</b>
          </p>
          <a href="tel:071455246">
            <h4 style={{ fontSize: "160%", margin: "0" }}>
              <PhoneIcon style={{ color: "#E90014", marginRight: "15px" }} />
              07145/5246
            </h4>{" "}
          </a>
          <HrWrapper>
            <hr />
            <span className="  uppercase">
              <b>Oder</b>
            </span>
            <hr />
          </HrWrapper>
          <ButtonWrapper>
            <ButtonWhiteFooter link="mailto:info@hengel-heizung.de">
              <span className={classes.emailIcon}>
                <EmailIcon fontSize="small"/>
              </span>
              Anfrage
            </ButtonWhiteFooter>
          </ButtonWrapper>
        </Grid>
        <Grid className={classes.gridFull} item xs={12} sm={12} md={6}>
          <p className="uppercase  ">
            <b>Bürozeiten</b>
          </p>

          <p className=" ">
            Montag bis Donnerstag
            <br />
            08:00 Uhr bis 12:00 Uhr
            <br />
            13:30 Uhr bis 16:00 Uhr
          </p>
          <p className=" ">
            Freitag <br />
            08:00 Uhr bis 12:00 Uhr
          </p>
          <p>
                  <b>Termine nach Vereinbarung</b>
                </p>
        </Grid>
      </Grid>

      <Grid className={classes.gridContainer} container spacing={0}>
        <Grid className={classes.greyleft} item xs={12} sm={6}>
          <p className="font__white">© 2024 Firma Kurt Hengel </p>
        </Grid>
        <Grid className={classes.greyright} item xs={12} sm={6}>
          <p className="font__white">
            <Link className="font__white" to="/impressum">Impressum</Link> /{" "}
            <Link className="font__white" to="/datenschutz">Datenschutz</Link>{" "}
          </p>
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer;

import React, { Component } from "react";
import "../App.css";
import styled from "styled-components";
import { gsap, Power1 } from "gsap";

import Layout from "../components/layout/layout";
import MitarbeiterLeft from "../components/mitarbeiterLeft";
import MitarbeiterRight from "../components/mitarbeiterRight";
import TeamHero from "../components/teamHero";
//Data

import data from "../data/team.json";
import DimitriosMarkou from "../images/DimitriosMarkou.jpg";
import UweEbert from "../images/UweEbert.jpg";
import NikoShaka from "../images/NikoShaka.jpg";
import AnastasiosThomaidis from "../images/AnastasiosThomaidis.jpg";
import AthanasiosMandratzis from "../images/AthanasiosMandratzis.jpg";
import TimEmmrich from "../images/TimEmmrich.jpg";
import BrittaKammerer from "../images/BrittaKammerer.jpg";



const MitarbeiterContainer = styled.div`
  width: 90vw;
  max-width: 1200px;
  margin: 0px auto 100px auto;
`;

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    

    let container = document.querySelector(".content_container");

    let tl = gsap.timeline();
    tl.to(container, { duration: 0, opacity: 0, y: 30 }).to(container, {
      duration: 0.8,
      opacity: 1,
      y: 0,
      ease: Power1.easeOut,
    });
  }

  

  render() {
    return (
      <Layout pageTitle="Firma Hengel" description="Nicht nur Tradition und Knowhow machen Hengel fir für die Zukunft. Es sind unsere einzigartigen Mitarbeiter, von denen uns viele seit Jahrzehnten die Treue halten.">
        <div>
          <TeamHero />
          <MitarbeiterContainer>
            <MitarbeiterLeft
              url={`url("${DimitriosMarkou}")`}
              alt={data.mitarbeiter[2].name}
              heading={data.mitarbeiter[2].heading}
              name={data.mitarbeiter[2].name}
              titel={data.mitarbeiter[2].titel}
              text={data.mitarbeiter[2].text}
              color={data.mitarbeiter[2].color}
            />
            <MitarbeiterLeft
              url={`url("${UweEbert}")`}
              alt={data.mitarbeiter[3].name}
              heading={data.mitarbeiter[3].heading}
              name={data.mitarbeiter[3].name}
              titel={data.mitarbeiter[3].titel}
              text={data.mitarbeiter[3].text}
              color={data.mitarbeiter[3].color}
            />
            <MitarbeiterRight
              url={`url("${NikoShaka}")`}
              alt={data.mitarbeiter[5].name}
              heading={data.mitarbeiter[5].heading}
              name={data.mitarbeiter[5].name}
              titel={data.mitarbeiter[5].titel}
              text={data.mitarbeiter[5].text}
              color={data.mitarbeiter[5].color}
            />
            <MitarbeiterRight
              url={`url("${AnastasiosThomaidis}")`}
              alt={data.mitarbeiter[6].name}
              heading={data.mitarbeiter[6].heading}
              name={data.mitarbeiter[6].name}
              titel={data.mitarbeiter[6].titel}
              text={data.mitarbeiter[6].text}
              color={data.mitarbeiter[6].color}
            />
            <MitarbeiterLeft
              url={`url("${AthanasiosMandratzis}")`}
              alt={data.mitarbeiter[7].name}
              heading={data.mitarbeiter[7].heading}
              name={data.mitarbeiter[7].name}
              titel={data.mitarbeiter[7].titel}
              text={data.mitarbeiter[7].text}
              color={data.mitarbeiter[7].color}
            />
            <MitarbeiterLeft
              url={`url("${TimEmmrich}")`}
              alt={data.mitarbeiter[8].name}
              heading={data.mitarbeiter[8].heading}
              name={data.mitarbeiter[8].name}
              titel={data.mitarbeiter[8].titel}
              text={data.mitarbeiter[8].text}
              color={data.mitarbeiter[8].color}
            />
            <MitarbeiterRight
              url={`url("${BrittaKammerer}")`}
              alt={data.mitarbeiter[9].name}
              heading={data.mitarbeiter[9].heading}
              name={data.mitarbeiter[9].name}
              titel={data.mitarbeiter[9].titel}
              text={data.mitarbeiter[9].text}
              color={data.mitarbeiter[9].color}
            />
          </MitarbeiterContainer>
         </div>
      </Layout>
    );
  }
}

export default Home;
